import React from 'react';
import Box from "../../../UI/General/Box";
import styles from "../../../../styles/reports.module.css";
import Text from "../../../UI/Typography/Text";

export const CustomTooltip = ({ payload, label, active }) => {
  if (active && payload) {
    return (
      <Box $radius={8} className={styles.tooltip}>
        <Text type={'body-1'} weight={600}>{label}</Text>
        <Text type={'body-2'}>Total Spent Amount: <Text weight={500}>{payload[0].value}</Text></Text>
      </Box>
    );
  }
  return null;
}
export const CustomTooltipLine = ({ payload, active }) => {
  if (active && payload) {
    return (
      <Box $radius={8} className={styles.tooltip}>
        <Text type={'body-2'}>Total Spent Amount: <Text weight={500}>{payload[0].value}</Text></Text>
        <Text type={'body-2'}>Total Invoiced Amount: <Text weight={500}>{payload[1].value}</Text></Text>
      </Box>
    );
  }
  return null;
}
