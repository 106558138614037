import React, {useEffect, useRef, useState} from 'react';
import styles from '../../../../styles/purchaseOrders.module.css';
import {Controller, useForm} from 'react-hook-form';
import generalStyles from '../../../../styles/general.module.css';
import * as Button from '../../../UI/Forms/Button';
import Modal from '../../../UI/Modal/Modal';
import Docs from './Docs';
import DataTableBase from '../../../UI/General/DataTableBase';
import Input from '../../../UI/Forms/Input';
import spmsServiceService from '../../../../services/spmsService.service';
import { useStore } from '../../../../store/store';
import Toast from '../../../UI/General/Toast';
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from "moment";

const ModalReceivedItems = (props) => {
  console.log(props.items)
  const { orderId } = useParams();
  const user = useStore((state) => state.user);
  const [tab, setTab] = useState('Notes');
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const requiredRef = useRef(false)
  const {
    handleSubmit,
    control,
    register,
    reset,
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      files: [],
      receivedDate: '',
      receivedBy: null,
      notes: '',
      items: [],
    }
  });

  const handleFull = () => {
    props?.items?.forEach(el => {
      const iof = getValues('items').indexOf(getValues('items').find(gvi => gvi?.purchaseOrderItemId === el.id))
      setValue(`items.${iof}.receivedQuantity`, el.quantity - el.receivedQuantity, { shouldValidate: true, shouldDirty: true })
    })
  }

  const columns = [
    {
      name: 'Description',
      selector: (row) => row?.purchaseOrderItem ? row?.purchaseOrderItem?.description : row.description,
      grow: 3
    },
    {
      name: 'Quantity Pending',
      selector: (row) => row?.purchaseOrderItem ? row?.purchaseOrderItem?.quantity - row.receivedQuantity : row.quantity - row.receivedQuantity,
    },
    {
      name: 'Quantity Received',
      selector: (row) => row.receivedQuantity,
    },
    {
      name: 'Quantity Delivered',
      cell: (row, index) => {
        return row.receiptStatus !== 'FULFILLED' ? (
          <>
            <Input
              $small
              {...register(`items.${index}.receivedQuantity`, {
                min: 0,
                validate: {
                  allowed: v => /\d+(\.\d+)?/g.test(v) || 'Numerical values only',
                  allowedDepth: v => /^[0-9]+([.][0-9]{1,3})?$/.test(v) || 'Max depth 0.000',
                },
              })}
              placeholder="0"
              className={errors?.items?.[index]?.receivedQuantity ? "error" : ""}
              defaultValue={0}
            />
            <input
              type="hidden"
              {...register(`items.${index}.purchaseOrderItemId`, { value: row.id })}
            />
          </>
        ) : (
          row.receivedQuantity
        )
      }
    },
  ]

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const onSubmit = async (data) => {
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }));
    const { files } = data;
    let newAttachments = [];
    if (files !== undefined && files.length > 0) {
      const filesArray = files.map(async (item) => ({
        name: item.name,
        document: await getBase64(item.attachment),
      }));
      newAttachments = await Promise.all(filesArray).then((result) => result);
    }
    // if (!newAttachments?.length) {
    //   setIsButtonsBlocked(false)
    //   setTab('Documents');
    //   requiredRef.current = true
    //   setError('files', { type: 'focus', message: 'Files required' });
    //   return;
    // }
    console.log(data.items)
    const updItems = data.items.map((item) => ({...item, receivedQuantity: parseFloat(item.receivedQuantity)})).filter((item) => item.receivedQuantity > 0);
    console.log(updItems)
    const newData = {
      receivedDate: moment(data.receivedDate).toISOString(),
      receivedBy: {
        userId: user?.id
      },
      notes: data.notes,
      items: updItems,
      attachments: newAttachments,
    };
    console.log(newData)
    spmsServiceService.createReceiveItemsPurchaseOrder(orderId, newData).then((r) => {
      reset();
      setToast({
        opened: true,
        message: 'Received Items has been submitted successfully',
        type: 'success',
        cb: () => (props.$callback(), props.$trigger(state => state +1), props.$close()),
      });
    }).catch((reason) => {
      setIsButtonsBlocked(false)
      setToast({
        opened: true,
        message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.errors[0].errorMessage,
        type: 'fail',
      });
    });
  };

  return (
    <>
      <Modal
        $show={props.$show}
        $close={props.$close}
        $title={"Received Items (" + props?.poNumber + ")"}
        $radius={12}
        $maxWidth={'1200px'}
      >
        <form onSubmit={handleSubmit(onSubmit)} className={styles.modal}>
          <div className={styles.modalFormInner}>
            <div className={generalStyles.fieldsThree}>
              <div className="inp-container">
                <Controller
                  name="receivedDate"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Received Date is required',
                    },
                    maxLength: {
                      value: 10,
                      message: 'Maximum 10 characters',
                    },
                  }}
                  render={({field}) => (
                    <DatePicker
                      {...field}
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      placeholderText="Select Date"
                      selected={field.value}
                      wrapperClassName="custom-datepicker"
                      customInput={
                        <Input
                          {...field}
                          $label="Received Date"
                          $labelRequired
                          $tooltip="The date the delivery was made"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                        />
                      }
                      onChange={(e) => {
                        field.onChange(e);
                        trigger(field.name);
                      }}
                    />
                  )}
                />
                {errors.receivedDate && <p className="error-message">{errors.receivedDate?.message}</p>}
              </div>
            </div>
            <DataTableBase
              data={props?.items?.receivedItems ? props?.items?.receivedItems : props?.items || []}
              columns={columns}
              pagination={false}
              className={styles.receivedItemsTable}
            />
            <Docs
              errors={errors}
              control={control}
              nameNotes={'notes'}
              labelNotes={'Notes'}
              nameFiles={'files'}
              labelFiles={'Documents'}
              tab={tab}
              setTab={setTab}
              requiredRef={requiredRef}
              clearErrors={clearErrors}
            />
          </div>
          <div className={generalStyles.pageButtons}>
            <Button.Main $primary $style="pink" onClick={() => handleFull()} type="button" disabled={isButtonsBlocked}>
              Receive in full
            </Button.Main>
            <Button.Main $primary $style="pink" type="submit" disabled={!isDirty || !isValid || isButtonsBlocked}>
              Save
            </Button.Main>
            <Button.Main $primary $style="gray" onClick={props.$close} type="button">
              Discard
            </Button.Main>
          </div>
        </form>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default ModalReceivedItems;
