import React, {useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import styles from "../../../../styles/reports.module.css";
import Text from "../../../UI/Typography/Text";
import Select from "react-select";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import s2pmsService from "../../../../services/spmsService.service";
import moment from "moment";
import {CustomTooltipLine} from "./Tooltips";

const ChartsOverall = ({periods, companyId, currency}) => {
  const [chartData, setChartData] = useState([])
  const {handleSubmit, control} = useForm({
    mode: 'onChange',
    defaultValues: {
      period: periods[0],
    },
  });

  const loadData = useCallback((departmentId, start, end = new Date()) => {
    if (companyId) {
      let currentDate = moment();
      let previousMonthDate = currentDate.subtract(start, 'month');
      let search = {
        companyId: companyId,
        startDate: previousMonthDate,
        endDate: end
      }
      s2pmsService.reportsOverallSpent(search).then(r => {
        if (r.data.message === "Operation Successful") {
          let data = {...r.data.data, monthlySpendReport: r.data.data.monthlySpendReport.map(el => ({...el, date: moment().month(el.month).format('MMMM')}))}
          setChartData(data)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }, [companyId])

  const onSubmit = (data) => {
    loadData(data?.department?.value, data?.period?.value)
  }

  useEffect(() => {
    loadData(undefined, periods[0].value)
  }, [loadData, periods])

  return (
    <form className={styles.chartBlock}>
      <div className={styles.groupTitle}>
        <Text type={'h4'} weight={600}>Overall Approved Spend</Text>
        <Text type={'body-2'} weight={500}>The overall spend metric will be the total spend for the specified period indicated per month</Text>
        <div className={styles.actions}>
          <Controller
            name={'period'}
            control={control}
            render={({field}) => (
              <Select
                {...field}
                className={'react-select-container small'}
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select Period"
                options={periods}
                onChange={(selectedOption) => {
                  field.onChange(selectedOption);
                  handleSubmit(onSubmit)();
                }}
              />
            )}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          width={400}
          height={200}
          data={chartData?.monthlySpendReport}
          margin={{
            top: 0,
            right: 0,
            left: 10,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey='date' />
          <YAxis label={{ value: `Value (${currency})`, angle: -90, position: 'insideLeft', style: {textAnchor: "middle"} }} />
          <Tooltip content={<CustomTooltipLine/>}/>
          <Line type="monotone" dataKey="totalApprovedAmount" stroke="#c85d9f" activeDot={{ r: 5 }} />
          <Line type="monotone" dataKey="totalInvoicedAmount" stroke="#39b7bc" activeDot={{ r: 5 }} />
        </LineChart>
      </ResponsiveContainer>
    </form>
  );
};

export default ChartsOverall;
