import React, {useEffect, useState} from 'react';
import Box from "../../components/UI/General/Box";
import s2pmsService from "../../services/spmsService.service";
import {useStore} from "../../store/store";
import styles from "../../styles/reports.module.css"
import {useAccessAllowed} from "../../hooks/useAccessAllowed";
import ChartsGl from "../../components/Admins/Reports/Charts/Charts_gl";
import ChartsVendor from "../../components/Admins/Reports/Charts/Charts_vendor";
import ChartsDepartment from "../../components/Admins/Reports/Charts/Charts_department";
import ChartsOverall from "../../components/Admins/Reports/Charts/Charts_overall";

const periods = [
  {label: "last 3 months", value: 3},
  {label: "last 6 months", value: 6},
  {label: "last 12 months", value: 12},
]

const Reports = () => {
  const activeCompany = useStore((state) => state.activeCompany);
  const accessReports = useAccessAllowed('Reports')
  const [departments, setDepartments] = useState([])

  useEffect(() => {
    s2pmsService.getDepartments(activeCompany.id).then(r => {
      if (r.data.message === "Operation Successful"){
        console.log(r.data.data)
        const temp = r.data.data.map(el => ({label: el.name, value: el.id}))
        temp.splice(0, 0, {label: "All", value: 0});
        setDepartments(temp)
      }
    }).catch(err => {
      console.log(err)
    })
  },[activeCompany])

  return (
    <Box $mobExtend $asHolder $noOverflow>
      {accessReports?.viewAll || accessReports?.viewOwn ? (
        <div className={styles.chartsGrid}>
          <ChartsOverall periods={periods} companyId={activeCompany?.id} currency={activeCompany?.defaultCurrency}/>
          <ChartsGl departments={departments} periods={periods} companyId={activeCompany?.id} currency={activeCompany?.defaultCurrency}/>
          <ChartsVendor departments={departments} periods={periods} companyId={activeCompany?.id} currency={activeCompany?.defaultCurrency}/>
          <ChartsDepartment periods={periods} companyId={activeCompany?.id} currency={activeCompany?.defaultCurrency}/>
        </div>
      ) : (
        <div>No permissions to view</div>
      )}
    </Box>
  );
}

export default Reports
