import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const GoogleReCaptcha = ({setCaptchaResponse}) => {
  const onChange = (value) => setCaptchaResponse(value)
  const onExpired = () => setCaptchaResponse(null)

  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_MODE === 'staging' ? process.env.REACT_APP_RECAPTCHA : process.env.REACT_APP_RECAPTCHA_PROD}
      onChange={onChange}
      onExpired={onExpired}
    />
  );
};

export default GoogleReCaptcha;
