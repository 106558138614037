import React, {useEffect, useState} from 'react';
import style from '../../styles/log_reg.module.css';
import Text from '../../components/UI/Typography/Text';
import Input, { Password } from '../../components/UI/Forms/Input';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Checkbox from '../../components/UI/Forms/Checkbox';
import 'react-phone-number-input/style.css';
import {useForm, Controller, useWatch} from 'react-hook-form';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import Modal from '../../components/UI/Modal/Modal';
import {Link, useNavigate} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Toast from '../UI/General/Toast';
import PasswordTest from '../UI/Forms/PasswordTest';
import requestsService from '../../services/requestsService.service';
import useGetUserLocation from '../../hooks/useGetUserLocation';
import {regExps} from "../../utils/regExps";

const SignUp = ({ setStatus, setUsername }) => {
  const navigate = useNavigate();
  const countryNav = useGetUserLocation();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const formDefault = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    repeat_password: '',
    roleName: 'CLIENT_ADMIN',
    agreement: false
  }
  const [values, setValues] = useState(null)
  const {
    handleSubmit,
    control,
    trigger,
    watch,
    formState: { errors, isDirty, isValid, dirtyFields },
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: formDefault,
    values
  });
  const [showModalPassword, setShowModalPassword] = useState(false);

  const formWatcher = watch()

  const onSubmit = (data) => {
    setToast((item) => ({...item, opened: false}));
    requestsService.checkUserExist(data.email, data.phoneNumber).then(() => {
      const newData = {
        username: data.email,
        preferred_username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          name: data.firstName + ' ' + data.lastName,
          given_name: data.firstName,
          family_name: data.lastName,
          phone_number: data.phoneNumber,
        },
      };
      Auth.signUp(newData)
        .then(() => {
          setUsername(data.email);
          setStatus('verify');
        })
        .catch((reason) => {
          console.log("singup error")
          setToast({
            opened: true,
            message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
            type: 'fail',
          });
        });
    }).catch((reason) => {
      console.log('check user Error')
      console.log(reason)
      setToast({
        opened: true,
        message: reason.response?.data?.message !== undefined ? reason.response.data.message : reason.response.data.error,
        type: 'fail',
      });
    });
  };

  useEffect(() => {
    let tempRegistrationData = ''
    if (formWatcher?.firstName !== "" || formWatcher?.lastName !== "" || formWatcher?.email !== "" || formWatcher?.phoneNumber !== "" || formWatcher?.password !== "" || formWatcher?.repeat_password !== ""){
      tempRegistrationData = formWatcher
      sessionStorage.setItem("registrationData", JSON.stringify(tempRegistrationData));
    }
  },[formWatcher])

  useEffect(() => {
    const sessionData = JSON.parse(sessionStorage.getItem("registrationData"));
    if (sessionData !== ''){
      setValues(sessionData)
    }
  },[])

  return (
    <>
      <div className={style.authHeader}>
        <Text type="h2" weight={600}>Sign Up</Text>
        <Text type="subtitle">We will be in touch to help you make the most of your trial.</Text>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={style.authForm}>
        <div className={style.inputs}>
          <div className="inp-container">
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'First name is required',
                },
                pattern: {
                  value: /^[-'a-zA-Z\s]+$/,
                  message: 'First name is not valid',
                },
                validate: (value) => value.trim() !== '' || 'First name is required',
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="First name *"
                  $iconName={'user'}
                  $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.firstName && <p className="error-message">{errors.firstName?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Last name is required',
                },
                pattern: {
                  value: /^[-'a-zA-Z\s]+$/,
                  message: 'Last name is not valid',
                },
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Last name *"
                  $iconName={'user'}
                  $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.lastName && <p className="error-message">{errors.lastName?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Email is required',
                },
                pattern: {
                  value: regExps.email,
                  message: 'Email is not valid',
                },
              }}
              render={({ field }) => (
                <Input
                  type="email"
                  placeholder="Business Email *"
                  $iconName={'mail'}
                  $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.email && <p className="error-message">{errors.email?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="phoneNumber"
              rules={{
                required: true,
                validate: {
                  isValid: (value) =>
                    isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || ''),
                },
              }}
              control={control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  limitMaxLength={true}
                  defaultCountry={countryNav}
                  countryCallingCodeEditable={false}
                  placeholder="Mobile / Landline  *"
                  international={true}
                  className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                />
              )}
            />
            {errors.phoneNumber?.type === 'required' && (
              <p className="error-message">Required phone number</p>
            )}
            {errors.phoneNumber?.type === 'isValid' && (
              <p className="error-message">Enter a valid phone number</p>
            )}
          </div>
          <div className="inp-container">
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Password is required',
                },
                minLength: {
                  value: 8,
                  message: 'At least 8 characters',
                },
                maxLength: {
                  value: 35,
                  message: 'Maximum 35 characters',
                },
                validate: {
                  oneLetter: (v) => /[a-z]+/.test(v) || 'At least one lowercase letter',
                  oneCapitalLetter: (v) => /[A-Z]+/.test(v) || 'At least one capital letter',
                  oneNumber: (v) => /\d+/.test(v) || 'At least one number',
                  oneSpecialChar: (v) => /[!@#$%&?]+/.test(v) || 'At least one special character',
                  onlyAllowed: (v) => /^[A-Za-z0-9!@#$%&?]*$/.test(v) || 'Not allowed symbols',
                },
              }}
              render={({ field }) => (
                <Password
                  placeholder="Password *"
                  $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger('repeat_password');
                  }}
                />
              )}
            />
            {errors.password && <p className="error-message">{errors.password?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="repeat_password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Password is required',
                },
                validate: {
                  isValid: (value) => value === getValues('password') || "Password don't match",
                },
              }}
              render={({ field }) => (
                <Password
                  placeholder="Re-Enter Password *"
                  $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger('password');
                  }}
                />
              )}
            />
            {errors.repeat_password && (
              <p className="error-message">{errors.repeat_password?.message}</p>
            )}
          </div>
        </div>
        <Controller
          name="roleName"
          control={control}
          defaultValue="CLIENT_ADMIN"
          render={({ field }) => <input type="hidden" {...field} />}
        />
        <div className={style.passwordRequirements}>
          <Text weight={400} type="body-2">
            <span className="link" onClick={() => setShowModalPassword(true)}>
              Password requirements
            </span>
          </Text>
        </div>
        <div className={style.checkboxWrapper + ' inp-container'}>
          <Controller
            name="agreement"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Agreement is required',
              },
            }}
            render={({ field }) => (
              <Checkbox
                {...field}
                $size={22}
                checked={field.value}
                label={
                  <span className={style.btnAfter}>
                    I accept POMS Online <Link to="/terms">Terms & Conditions</Link> *
                  </span>
                }
                className={errors.hasOwnProperty(field.name) ? 'error' : ''}
              />
            )}
          />
          {errors.agreement && <p className="error-message">{errors.agreement?.message}</p>}
        </div>
        <div className={style.btnBefore}>
          <Text type="body-2">
            By registering, you confirm that you agree to the storing and processing of your
            personal data by POMS Online as described in the{' '}
            <Link to="/policy">Privacy Statement</Link>.
          </Text>
        </div>
        <div className={style.btn}>
          <Button.Main
            $primary
            $full
            $style="pink"
            $iconRight
            type="submit"
            disabled={!isValid}
          >
            <span>Sign Up</span>
            <Icon $width={24} $height={24} $icon="arrow-right" />
          </Button.Main>
        </div>
      </form>
      <div className={style.btnAfter}>
        <Text type="body-1" weight={500}>
          Already have an account? <Link to="/login">Log In</Link>
        </Text>
      </div>
      <Modal
        $show={showModalPassword}
        $close={() => setShowModalPassword(false)}
        $title="Requirements"
        $radius={16}
        $closableOutside
      >
        <div className={style.modalSmallPadding}>
          <PasswordTest name={'password'} isDirty={dirtyFields} control={control} />
        </div>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb}/>
      ) : null}
    </>
  );
};

export default SignUp;
