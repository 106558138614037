import React, {useState, useRef, useEffect} from 'react';

import {
  CardElement,
  Elements,
  RecurlyProvider,
  useRecurly,
 useCheckoutPricing
} from '@recurly/react-recurly';

import s2pmsService from '../../services/spmsService.service'
import {useStore} from "../../store/store";

function CheckoutPricingForm () {
  const [recurlyError, setRecurlyError] = useState(null);
  const [pricingFormState, setPricingFormState] = useState({
    plan: '',
    planQuantity: '',
    itemCode: '',
    itemQuantity: '',
    coupon: '',
    giftCard: '',
    currency: '',
    billingCountry: '',
    billingPostalCode: '',
    billingVatNumber: '',
    shippingCountry: '',
    shippingPostalCode: '',
    shippingVatNumber: ''
  });

  const [{ price, loading }, setPricing] = useCheckoutPricing(null, setRecurlyError);
  const showPrice = !loading && !recurlyError;

  function handleChange (name, value) {
    const newState = { ...pricingFormState, [name]: value };
    setPricingFormState(newState);
  };

  useEffect(() => {
    setRecurlyError(null);

    const subscriptions = pricingFormState.plan ? [{
      plan: pricingFormState.plan,
      quantity: pricingFormState.planQuantity
    }] : [];
    const adjustments = pricingFormState.itemCode ? [{
      itemCode: pricingFormState.itemCode,
      quantity: pricingFormState.itemQuantity
    }] : [];
    const address = {
      country: pricingFormState.billingCountry,
      postal_code: pricingFormState.billingPostalCode
    };
    const shippingAddress = {
      country: pricingFormState.shippingCountry,
      postal_code: pricingFormState.shippingPostalCode
    };

    setPricing({ ...pricingFormState, subscriptions, adjustments, address, shippingAddress });
  }, [pricingFormState, setPricing]);

  return (
    <div className="DemoSection">
      <div>
        <h3>Subscription</h3>
        <select value={pricingFormState.plan} onChange={e => handleChange('plan', e.target.value)}>
          <option value="">Select a plan</option>
          <option value="001">Basic</option>
          <option value="003">Premium</option>
          <option value="error">Error</option>
        </select>
        <input
          type="number"
          value={pricingFormState.planQuantity}
          onChange={e => handleChange('planQuantity', e.target.value)}
          placeholder="Plan quantity"
          min="0"
        />
        <select
          type="text"
          value={pricingFormState.currency}
          onChange={e => handleChange('currency', e.target.value)}
          placeholder="Currency"
        >
          <option value="USD">USD</option>
          <option value="ZAR">ZAR</option>
        </select>
      </div>
      <div style={{ marginTop: '10px' }}>
        <h3>Item/adjustments</h3>
        <input
          type="text"
          value={pricingFormState.itemCode}
          onChange={e => handleChange('itemCode', e.target.value)}
          placeholder="Item code"
        />
        <input
          type="number"
          value={pricingFormState.itemQuantity}
          onChange={e => handleChange('itemQuantity', e.target.value)}
          placeholder="Quantity"
          min="0"
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <h3>Coupon and giftcard</h3>
        <input
          type="text"
          value={pricingFormState.coupon}
          onChange={e => handleChange('coupon', e.target.value)}
          placeholder="Coupon"
        />
        <input
          type="text"
          value={pricingFormState.giftCard}
          onChange={e => handleChange('giftCard', e.target.value)}
          placeholder="Gift card"
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <h3>Billing address</h3>
        <input
          type="text"
          value={pricingFormState.billingCountry}
          onChange={e => handleChange('billingCountry', e.target.value)}
          placeholder="Country"
        />
        <input
          type="text"
          value={pricingFormState.billingPostalCode}
          onChange={e => handleChange('billingPostalCode', e.target.value)}
          placeholder="Postal code"
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <h3>Shipping address</h3>
        <input
          type="text"
          value={pricingFormState.shippingCountry}
          onChange={e => handleChange('shippingCountry', e.target.value)}
          placeholder="Country"
        />
        <input
          type="text"
          value={pricingFormState.shippingPostalCode}
          onChange={e => handleChange('shippingPostalCode', e.target.value)}
          placeholder="Postal code"
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        {recurlyError ? <span style={{ color: 'red' }}>{recurlyError.message}</span> : ''}
        {showPrice ? (
          <span>
            {'Subtotal: '}
            <span>{`${price.currency.symbol}${price.now.subtotal}`}</span>
          </span>
        ) : null}
        {loading && 'Loading'}
      </div>
    </div>
  );
};


const handleBlur = () => console.log('[blur]');
const handleChange = change => console.log('[change]', change);
const handleFocus = () => console.log('[focus]');
const handleReady = () => console.log('[ready]');

export function CardElementDemo (props) {
  return (
    <div className="DemoSection">
      <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY}>
        <Elements>
          <CardForm />
          {/*<CheckoutPricingForm/>*/}
        </Elements>
      </RecurlyProvider>
    </div>
  );
}

function CardForm (props) {
  const recurly = useRecurly();
  const formRef = useRef();

  const companyId = useStore((state) => state.activeCompany?.id);

  const handleSubmit = event => {
    if (event.preventDefault) event.preventDefault();
    recurly.token(formRef.current, (err, token) => {
      if (err) {
        console.log('[error]', err);
      }
      else {
        console.log('[token]', token);
        s2pmsService.subscriptionBillingInfo(companyId, {tokenId: token.id}).then(r => {
          console.log(r)
        }).catch(err => {
          console.log(err)
        })
      }
    });
  };

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <div>
        <input
          data-recurly="first_name"
          placeholder="First Name"
          defaultValue="John"
        />
        <input
          data-recurly="last_name"
          placeholder="Last Name"
          defaultValue="Smith"
        />
        <input
          data-recurly="postal_code"
          placeholder="Postal Code"
          defaultValue="98552"
        />
        {/*strange behavior*/}

        <input
          data-recurly="address1"
          placeholder="Address"
          defaultValue="1313 Main St."
        />
        <input
          data-recurly="state"
          placeholder="State"
          defaultValue="WA"
        />
        <input
          data-recurly="city"
          placeholder="City"
          defaultValue="Hope"
        />
        <input
          data-recurly="country"
          placeholder="Country"
          defaultValue="US"
        />

      </div>
      <CardElement
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onReady={handleReady}
        onSubmit={handleSubmit}
      />
      <div>
        <button>Pay</button>
      </div>
    </form>
  );
}
