import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import Box from '../../components/UI/General/Box';
import Icon from '../../components/UI/General/Icon';
import styles from '../../styles/dashboard.module.css';
import {useStore} from '../../store/store';
import {useShallow} from 'zustand/react/shallow';
import {useAccessAllowed} from '../../hooks/useAccessAllowed';
import Widget from '../../components/Admins/Dashboard/Widget';
import {useSubscriptionAllowed} from "../../hooks/useSubscriptionAllowed";
import Loading from "../../components/Loading";
import spmsServiceService from "../../services/spmsService.service";

function Dashboard() {
  const user = useStore(useShallow((state) => state.user));
  const activeCompany = useStore((state) => state.activeCompany);
  const hasFullCompanies = useStore((state) => state.fullCompanies);
  const stepsDone = useStore(useShallow((state) => state.stepsDone));
  const [loadedCardData, setLoadedCardData] = useState(false)
  const [cardData, setCardData] = useState({
    myApprovedPurchaseOrders: 0,
    myRequisitionsPendingApproval: 0,
    myPurchaseOrdersPendingApproval: 0,
    myReceivedPurchaseOrders: 0,
    myReadyToPayPurchaseOrders: 0,
    myCompletedPurchaseOrders: 0,
    requisitionsPendingMyApproval: 0,
    purchaseOrdersPendingMyApproval: 0,
    invoicesPendingMyApproval: 0,
    budgetRequestsPendingMyApproval: 0,
    vendorsPendingMyApproval: 0,
  });

  const access = useAccessAllowed(['Requisition', 'Purchase_Order', 'Budgets', 'Vendors', 'Payments', 'Receiving', 'Invoicing', 'Reports'])
  const accessSubscription = useSubscriptionAllowed()

  useEffect(() => {
    if (!!activeCompany?.id) {
      spmsServiceService.getMetrics(activeCompany?.id).then(r => {
        if (r.data.message === "Operation Successful") {
          const data = {
            myApprovedPurchaseOrders: parseInt(r.data.data.myApprovedPurchaseOrders ?? 0),
            myRequisitionsPendingApproval: parseInt(r.data.data.myRequisitionsPendingApproval ?? 0),
            myPurchaseOrdersPendingApproval: parseInt(r.data.data.myPurchaseOrdersPendingApproval ?? 0),
            myReceivedPurchaseOrders: parseInt(r.data.data.myReceivedPurchaseOrders ?? 0),
            myReadyToPayPurchaseOrders: parseInt(r.data.data.myReadyToPayPurchaseOrders ?? 0),
            myCompletedPurchaseOrders: parseInt(r.data.data.myCompletedPurchaseOrders ?? 0),
            requisitionsPendingMyApproval: parseInt(r.data.data.requisitionsPendingMyApproval ?? 0),
            purchaseOrdersPendingMyApproval: parseInt(r.data.data.purchaseOrdersPendingMyApproval ?? 0),
            invoicesPendingMyApproval: parseInt(r.data.data.invoicesPendingMyApproval ?? 0),
            budgetRequestsPendingMyApproval: parseInt(r.data.data.budgetRequestsPendingMyApproval ?? 0),
            vendorsPendingMyApproval: parseInt(r.data.data.vendorsPendingMyApproval ?? 0),
          }
          setCardData(data);
          setLoadedCardData(true);
        }
      })
    }
  }, [activeCompany]);

  if ((!loadedCardData || !access?.budgets || !accessSubscription) && stepsDone) {
    return <Loading text={"Loading company data..."}/>
  }

  return (
    <>
      {!!hasFullCompanies?.length || user.roles.filter(el => el.company?.id === activeCompany?.id && el.role?.name === "END_USER")?.length > 1 ? null : <Widget/>}

      {stepsDone && (
        <div className={styles.countersWrapper}>
          <div className={styles.countersCol}>
            <div className={styles.counters}>
              <Link to={!activeCompany?.requisitionsRequired ? '' : '/requisitions/overview/pending'}>
                <Box $noPadding $radius={12} className={!activeCompany?.requisitionsRequired ? styles.counter + ' ' + styles.counterDisabled : styles.counter}>
                  <Text type="h1" className={styles.counterValue}>
                    {cardData.myRequisitionsPendingApproval}
                  </Text>
                  <Text type="body-1" weight={500} className={styles.counterLabel}>My Requisitions Pending Approval</Text>
                  <div className={styles.counterIcon}>
                    <Icon $width={36} $height={36} $icon="dashboard-counter-1"/>
                  </div>
                </Box>
              </Link>
              <Link to="/purchase-orders/overview/pending">
                <Box $radius={12} className={styles.counter} $noPadding>
                  <Text type="h1" className={styles.counterValue}>
                    {cardData.myPurchaseOrdersPendingApproval}
                  </Text>
                  <Text type="body-1" weight={500} className={styles.counterLabel}>My POs Pending Approval</Text>
                  <div className={styles.counterIcon}>
                    <Icon $width={36} $height={36} $icon="dashboard-counter-2" />
                  </div>
                </Box>
              </Link>
              <Link to="/purchase-orders/overview/approved">
                <Box $radius={12} className={styles.counter} $noPadding>
                  <Text type="h1" className={styles.counterValue}>
                    {cardData.myApprovedPurchaseOrders}
                  </Text>
                  <Text type="body-1" weight={500} className={styles.counterLabel}>My POs Approved</Text>
                  <div className={styles.counterIcon}>
                    <Icon $width={36} $height={36} $icon="dashboard-counter-3" />
                  </div>
                </Box>
              </Link>
              <Link to="/receiving/overview/not_fulfilled">
                <Box $radius={12} className={styles.counter} $noPadding>
                  <Text type="h1" className={styles.counterValue}>{cardData.myReceivedPurchaseOrders}</Text>
                  <Text type="body-1" weight={500} className={styles.counterLabel}>My POs Received</Text>
                  <div className={styles.counterIcon}><Icon $width={36} $height={36} $icon="dashboard-counter-4" /></div>
                </Box>
              </Link>
              <Link to="/invoices/overview/approved">
                <Box $radius={12} className={styles.counter} $noPadding>
                  <Text type="h1" className={styles.counterValue}>{cardData.myReadyToPayPurchaseOrders}</Text>
                  <Text type="body-1" weight={500} className={styles.counterLabel}>My POs Ready to Pay</Text>
                  <div className={styles.counterIcon}><Icon $width={36} $height={36} $icon="dashboard-counter-6" /></div>
                </Box>
              </Link>
              <Link to="/payments/overview/fully_paid">
                <Box $radius={12} className={styles.counter} $noPadding>
                  <Text type="h1" className={styles.counterValue}>{cardData.myCompletedPurchaseOrders}</Text>
                  <Text type="body-1" weight={500} className={styles.counterLabel}>My POs Completed</Text>
                  <div className={styles.counterIcon}><Icon $width={36} $height={36} $icon="dashboard-counter-7"/></div>
                </Box>
              </Link>
            </div>
          </div>

          <div className={styles.countersCol}>
            <div className={styles.counters}>
              {access?.requisition?.approve ? (
                <Link to={!activeCompany?.requisitionsRequired ? '' : '/requisitions/overview/pending'}>
                  <Box $noPadding $radius={12} className={!activeCompany?.requisitionsRequired ? styles.counter + ' ' + styles.counterDisabled : styles.counter}>
                    <Text type="h1" className={styles.counterValue}>
                      {cardData.requisitionsPendingMyApproval}
                    </Text>
                    <Text type="body-1" weight={500} className={styles.counterLabel}>Requisitions</Text>
                    <div className={styles.counterIcon}>
                      <Icon $width={36} $height={36} $icon="dashboard-counter-1"/>
                    </div>
                  </Box>
                </Link>
              ) : null}
              {access?.purchase_order?.approve ? (
                <Link to="/purchase-orders/overview/pending">
                  <Box $noPadding $radius={12} className={styles.counter}>
                    <Text type="h1" className={styles.counterValue}>
                      {cardData.purchaseOrdersPendingMyApproval}
                    </Text>
                    <Text type="body-1" weight={500} className={styles.counterLabel}>Purchase Orders</Text>
                    <div className={styles.counterIcon}>
                      <Icon $width={36} $height={36} $icon="dashboard-counter-2"/>
                    </div>
                  </Box>
                </Link>
              ) : null}
              {access?.invoicing?.approve ? (
                <Link to="/invoices/overview/pending_approval">
                  <Box $radius={12} className={styles.counter} $noPadding>
                    <Text type="h1" className={styles.counterValue}>
                      {cardData.invoicesPendingMyApproval}
                    </Text>
                    <Text type="body-1" weight={500} className={styles.counterLabel}>Invoices</Text>
                    <div className={styles.counterIcon}>
                      <Icon $width={36} $height={36} $icon="dashboard-counter-3"/>
                    </div>
                  </Box>
                </Link>
              ) : null}
              {access?.budgets?.approve ? (
                <Link to="/budgets/pending">
                  <Box $radius={12} className={styles.counter} $noPadding>
                    <Text type="h1" className={styles.counterValue}>
                      {cardData.budgetRequestsPendingMyApproval}
                    </Text>
                    <Text type="body-1" weight={500} className={styles.counterLabel}>Budgets</Text>
                    <div className={styles.counterIcon}>
                      <Icon $width={36} $height={36} $icon="dashboard-counter-2"/>
                    </div>
                  </Box>
                </Link>
              ) : null}
              {access?.vendors?.approve ? (
                <Link to="/vendors/pending-approval">
                  <Box $radius={12} className={styles.counter} $noPadding>
                    <Text type="h1" className={styles.counterValue}>{cardData.vendorsPendingMyApproval}</Text>
                    <Text type="body-1" weight={500} className={styles.counterLabel}>Vendors</Text>
                    <div className={styles.counterIcon}><Icon $width={36} $height={36} $icon="dashboard-counter-4"/></div>
                  </Box>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
